
































































































































































































































































































































import { SearchBuilder } from "@/builder";
import { setNumbering } from "@/helpers/common";
import { debounce, debounceProcess } from "@/helpers/debounce";
import {
  useBlob,
  useBranch,
  useContactListFindById,
  useDate,
  useDisabledFromTomorrow,
  useFindMasterType,
  useInvoiceAP,
  useLocalFilter,
  useMapMasterTypeToOption,
  useProduct,
  usePurchaseOrder,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE, PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataInvoiceAp,
  RequestParamDownloadPOGRInvoiceReport,
  RequestParamListPOGRInvoiceReport,
} from "@/models/interface/invoiceAp.interface";
import { DetailPoListDTO } from "@/models/interface/IPurchaseOrderReportDetailDTO";
import { DataListProduct } from "@/models/interface/logistic.interface";
import { ProductResponseDto } from "@/models/interface/master-product";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

type FormValues = {
  partId: string;
  partNumber: string;
  branchId: string;
  poType: string;
  supplierId: string;
  poId: string;
  invoiceApId: string;
  invoiceSupplierNo: string;
  poDate: null | Moment[];
  invoiceDate: null | Moment[];
};

type FieldSource = keyof Omit<FormValues, "poDate" | "invoiceDate">;

type Row = DetailPoListDTO & { key: number; no: number };

@Component
export default class PurchaseOrderDetailReport extends Mixins(
  MNotificationVue
) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useDisabledFromTomorrow = useDisabledFromTomorrow;
  @Ref("FormReport") formReport!: FormModel;
  useLocalFilter = useLocalFilter;
  formModel: FormValues = {
    branchId: "",
    poType: "",
    supplierId: "",
    poId: "",
    invoiceApId: "",
    invoiceSupplierNo: "",
    poDate: null,
    invoiceDate: null,
    partId: "",
    partNumber: "",
  };

  // add field rules here
  formRules = {};

  loading = {
    part: false,
    download: false,
    invoiceSupplier: false,
    invoice: false,
    poType: false,
    poNumber: false,
    find: false,
  };

  optPoTypes: Option[] = [];
  optPoNumbers: Option[] = [];
  optParts: Option<ProductResponseDto>[] = [];
  optInvoiceNumbers: Option[] = [];
  optInvoiceSupplierNumbers: Option<DataInvoiceAp>[] = [];

  columns = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      width: 100,
      key: "no",
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branchName",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_po_date"),
      dataIndex: "poDate",
      key: "poDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_po_number"),
      dataIndex: "poNumber",
      key: "poNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_po_type"),
      dataIndex: "purchaseOrderType",
      key: "purchaseOrderType",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_po_status"),
      dataIndex: "poStatus",
      key: "poStatus",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_supplier"),
      dataIndex: "supplierName",
      key: "supplierName",
      width: 300,
      scopedSlots: { customRender: "nullable" },
    },
  ];
  detailColumn = {
    grDetail: [
      {
        title: this.$t("lbl_good_receipt_date"),
        dataIndex: "grDate",
        key: "grDate",
        scopedSlots: { customRender: "date" },
      },
      {
        title: this.$t("lbl_good_receipt_number"),
        dataIndex: "grNumber",
        key: "grNumber",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_part_number"),
        dataIndex: "grPartCode",
        key: "grPartCode",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_part_name"),
        dataIndex: "grPartName",
        key: "grPartName",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_receive_qty"),
        dataIndex: "grQty",
        key: "grQty",
        scopedSlots: { customRender: "number" },
      },
      {
        title: this.$t("lbl_qty_po"),
        dataIndex: "grQtyPo",
        key: "grQtyPo",
        scopedSlots: { customRender: "number" },
      },
    ],
    invoiceDetail: [
      {
        title: this.$t("lbl_invoice_ap_number"),
        dataIndex: "invoiceApNumber",
        key: "invoiceApNumber",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_invoice_supplier_number"),
        dataIndex: "invoiceSupplierNo",
        key: "invoiceSupplierNo",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_invoice_date"),
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        scopedSlots: { customRender: "date" },
      },
      {
        title: this.$t("lbl_tax_invoice_date"),
        dataIndex: "taxInvoiceDate",
        key: "taxInvoiceDate",
        scopedSlots: { customRender: "date" },
      },
      {
        title: this.$t("lbl_tax_invoice_number"),
        dataIndex: "taxInvoiceNo",
        key: "taxInvoiceNo",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_currency_rate"),
        dataIndex: "currencyRate",
        key: "currencyRate",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_discount"),
        dataIndex: "discountValue",
        key: "discountValue",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_subtotal"),
        dataIndex: "invoiceSubtotal",
        key: "invoiceSubtotal",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_income_tax"),
        dataIndex: "incomeTaxValue",
        key: "incomeTaxValue",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_tax_amount"),
        dataIndex: "taxValue",
        key: "taxValue",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_base_amount"),
        dataIndex: "baseAmount",
        key: "baseAmount",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_cogs_short"),
        dataIndex: "cogsPrice",
        key: "cogsPrice",
        scopedSlots: { customRender: "currency" },
      },
    ],
    poDetail: [
      {
        title: this.$t("lbl_part_number"),
        dataIndex: "partCode",
        key: "partCode",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_part_name"),
        dataIndex: "partName",
        key: "partName",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_specification"),
        dataIndex: "specification",
        key: "specification",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_merk"),
        dataIndex: "merk",
        key: "merk",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_qty"),
        dataIndex: "poLineQty",
        key: "poLineQty",
        scopedSlots: { customRender: "number" },
      },
      {
        title: this.$t("lbl_discount"),
        dataIndex: "discountValue",
        key: "discountValue",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_subtotal"),
        dataIndex: "invoiceSubtotal",
        key: "invoiceSubtotal",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_tax_amount"),
        dataIndex: "taxValue",
        key: "taxValue",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_income_tax"),
        dataIndex: "incomeTaxValue",
        key: "incomeTaxValue",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_base_amount"),
        dataIndex: "baseAmount",
        key: "baseAmount",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_cogs"),
        dataIndex: "cogsPrice",
        key: "cogsPrice",
        scopedSlots: { customRender: "currency" },
      },
    ],
    returnDetail: [
      {
        title: this.$t("lbl_return_date"),
        dataIndex: "returnDate",
        key: "returnDate",
        scopedSlots: { customRender: "date" },
      },
      {
        title: this.$t("lbl_return_number"),
        dataIndex: "returnNo",
        key: "returnNo",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_return_amount"),
        dataIndex: "qtyReturn",
        key: "qtyReturn",
        scopedSlots: { customRender: "number" },
      },
    ],
  };
  dataSource: Row[] = [];
  pagination = {
    showSizeChanger: true,
    showTotal: (total: number) => this.$t("lbl_total_items", { total }),
    current: 1,
    pageSize: 10,
    total: 0,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
  };

  created(): void {
    this.fetchPoTypes();
    this.fetchPoNumbers(new RequestQueryParams());
    this.fetchInvoiceNumbers(new RequestQueryParams());
    this.fetchInvoiceSupplierNumbers(new RequestQueryParams());
    this.fetchPart(new RequestQueryParams());

    this.onSearchPo = debounceProcess(this.onSearchPo);
    this.searchPart = debounceProcess(this.searchPart);
  }

  findData(params?: Partial<RequestParamListPOGRInvoiceReport>): void {
    const { findAllPOGRInvoice } = useInvoiceAP();
    this.loading.find = true;
    findAllPOGRInvoice(params)
      .then(response => {
        this.dataSource = response.data.map<Row>((item, i) => ({
          ...item,
          key: setNumbering(params?.page ?? 0, params?.limit ?? 0, i),
          no: setNumbering(params?.page ?? 0, params?.limit ?? 0, i),
        }));
        this.pagination.total = response.totalElements;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  validateForm(source: "download" | "find" | "pagination"): void {
    this.formReport.validate(() => {
      this.preDownload().then(params => {
        if (source === "download") {
          this.download(params);
        } else {
          const { current } = this.pagination;
          this.pagination.current = source === "find" ? FIRST_PAGE : current; // reset current page when param changed
          this.findData({
            ...params,
            limit: this.pagination.pageSize,
            page: this.pagination.current - ONE,
          });
        }
      });
    });
  }

  async preDownload(): Promise<Partial<RequestParamDownloadPOGRInvoiceReport>> {
    const { formModel: field } = this;
    const { toStartDay, toEndDay } = useDate();
    const headerReport = {
      company:
        this.$store.state.authStore.authData.companyName ||
        "PT. SATRIA PIRANTI PERKASA",
      branch: "ALL",
      poNumber: "ALL",
      invoiceApNo: "ALL",
      poDateFrom: "ALL",
      poDateTo: "ALL",
      invoiceDateFrom: "ALL",
      invoiceDateTo: "ALL",
      poType: field.poType || "ALL",
      supplier: "ALL",
      invoiceSupplierNo: field.invoiceSupplierNo || "ALL",
      partNumber: field.partNumber || "ALL",
    };
    const params: Partial<RequestParamDownloadPOGRInvoiceReport> = {
      branchId: field.branchId,
      poType: field.poType,
      supplierId: field.supplierId,
      poId: field.poId,
      invoiceApId: field.invoiceApId,
      invoiceSupplierNo: field.invoiceSupplierNo,
      partNumber: field.partNumber,
      sorts: "",
      params: "",
    };

    if (field.branchId) {
      const { findAll } = useBranch();
      const response = await findAll({ search: "secureId~" + field.branchId });
      const [data] = response.data;
      if (data) headerReport.branch = data.name;
    }

    if (field.supplierId) {
      const response = await useContactListFindById(field.supplierId);
      if (response) headerReport.supplier = response.fullName || "";
    }

    if (field.poId) {
      const { findAll } = usePurchaseOrder();
      const response = await findAll({ search: "secureId~" + field.poId });
      const [data] = response.data;
      if (data) headerReport.poNumber = data.documentNumber || "";
    }

    if (field.invoiceApId) {
      const { findAll } = useInvoiceAP();
      const response = await findAll({
        search: "secureId~" + field.invoiceApId,
      });
      const [data] = response.data;
      if (data) headerReport.invoiceApNo = data.documentNumber || "";
    }

    if (field.poDate && field.poDate.length) {
      const [start, end] = field.poDate;
      params.poDateFrom = toStartDay(start).format(DATE_FORMAT_YYYY_MM_DD);
      params.poDateTo = toEndDay(end).format(DATE_FORMAT_YYYY_MM_DD);
      headerReport.poDateFrom = params.poDateFrom;
      headerReport.poDateTo = params.poDateTo;
    }

    if (field.invoiceDate && field.invoiceDate.length) {
      const [start, end] = field.invoiceDate;
      params.invoiceDateFrom = toStartDay(start).format(DATE_FORMAT_YYYY_MM_DD);
      params.invoiceDateTo = toEndDay(end).format(DATE_FORMAT_YYYY_MM_DD);
      headerReport.invoiceDateFrom = params.invoiceDateFrom;
      headerReport.invoiceDateTo = params.invoiceDateTo;
    }

    for (const property in headerReport) {
      params.params += headerReport[property] + ",";
    }

    return params;
  }

  download(params?: Partial<RequestParamDownloadPOGRInvoiceReport>): void {
    const { downloadPOGRInvoiceReport } = useInvoiceAP();
    const { toDownload } = useBlob();
    this.loading.download = true;
    downloadPOGRInvoiceReport(params)
      .then(response => {
        toDownload(response, "po_detail_report.xlsx");
        this.showNotifSuccess("notif_download_report_success");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  onChange(value: string, source: FieldSource): void {
    this.formModel[source] = value;
  }

  fetchPoTypes(): void {
    this.loading.poType = true;
    useFindMasterType("PURCHASE_ORDER_TYPE")
      .then(response => {
        this.optPoTypes = useMapMasterTypeToOption(response);
      })
      .finally(() => (this.loading.poType = false));
  }

  fetchPoNumbers(params: RequestQueryParamsModel): void {
    const builder = new SearchBuilder();
    const q = builder
      .push(["status", "DRAFT"], { not: true })
      .and()
      .push(["status", "CANCELLED"], { not: true })
      .build();
    const defaultQuery = [q];
    if (params.search) defaultQuery.push(params.search);
    params.search = defaultQuery.join(builder.AND);
    this.loading.poNumber = true;
    const { findAll, toOptions } = usePurchaseOrder();
    findAll(params)
      .then(response => {
        this.optPoNumbers = toOptions(response.data);
      })
      .finally(() => (this.loading.poNumber = false));
  }

  onSearchPo(value = ""): void {
    const builder = new SearchBuilder();
    let q = "";
    if (value) {
      q = builder.push(["documentNumber", value], { like: "both" }).build();
    }
    this.fetchPoNumbers({ search: q });
  }

  fetchInvoiceNumbers(params: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useInvoiceAP();
    this.loading.invoice = true;
    findAll(params)
      .then(response => {
        this.optInvoiceNumbers = toOptions(response.data);
      })
      .finally(() => {
        this.loading.invoice = false;
      });
  }

  onSearchInvoice(value = "", source: "invoiceAP" | "invoiceSupplier"): void {
    let q = "";
    const builder = new SearchBuilder();
    if (value) {
      const searchKey =
        source === "invoiceAP" ? "documentNumber" : "invoiceSupplierNo";
      q = builder.push([searchKey, value], { like: "both" }).build();
    }
    const params = new RequestQueryParams(q);
    debounce(() => {
      if (source === "invoiceAP") {
        this.fetchInvoiceNumbers(params);
      } else if (source === "invoiceSupplier") {
        this.fetchInvoiceSupplierNumbers(params);
      }
    });
  }

  fetchInvoiceSupplierNumbers(params: RequestQueryParamsModel): void {
    const builder = new SearchBuilder();
    const q = builder
      .push(["invoiceSupplierNo", "null"], { not: true })
      .build();
    const defaultQuery = [q];

    if (params.search) defaultQuery.push(params.search);
    params.search = defaultQuery.join(builder.AND);

    const { findAll, toSupplierNoOptions } = useInvoiceAP();
    this.loading.invoiceSupplier = true;
    findAll(params)
      .then(response => {
        this.optInvoiceSupplierNumbers = toSupplierNoOptions(response.data);
      })
      .finally(() => {
        this.loading.invoiceSupplier = false;
      });
  }

  reset(): void {
    this.formReport.resetFields();
    this.formModel.partNumber = "";
  }

  onChangeTable(pagination): void {
    const { pageSize } = this.pagination;
    this.pagination.current = pagination.current;

    // reset current page if page size changed
    if (pagination.pageSize !== pageSize) {
      this.pagination.current = FIRST_PAGE;
    }

    this.pagination.pageSize = pagination.pageSize;
    this.validateForm("pagination");
  }

  fetchPart(params: RequestQueryParams): void {
    const { findAll, toOptions } = useProduct();
    this.loading.part = true;
    findAll(params)
      .then(res => {
        this.optParts = toOptions(res.data);
      })
      .finally(() => {
        this.loading.part = false;
      });
  }

  searchPart(search = ""): void {
    const { searchBy } = useProduct();
    const params = new RequestQueryParams();
    params.search = searchBy({ code: search, name: search });

    this.fetchPart(params);
  }

  onChangePart(e: string | null): void {
    const option: Option<ProductResponseDto> | undefined = this.optParts.find(
      item => item.value === e
    );
    this.formModel.partNumber = option?.meta?.code || "";
  }
}
